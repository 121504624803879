const GamePortfolioPage = () => {
    return (
        <div>
            <h1>
                Game Portfolio Page placeholder
            </h1>
            <p>
                sus
            </p>
        </div>
    )
}

export default GamePortfolioPage; 