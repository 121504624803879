export const areas = {
  coachCatalogue: 'coach-catalogue-area',
  coachPortalBioSubmit: 'coach-portal-bio-submit-area',
  coachPortalUserImage: 'coach-portal-user-image-area',
  coachPortalUserInfo: 'coach-portal-user-info-area',
  profileUserInfo: 'profile-user-info-area',
  profileUserBioSubmit: 'profile-user-bio-submit-area',
  profileCoachInfo: 'profile-coach-info-area',
  simulatorPortfolioUserInfo: 'simulator-portfolio-user-info-area',
  simulatorPortfolioTradeHistory: 'simulator-portfolio-trade-history-area',
  simulatorPortfolioHoldings: 'simulator-portfolio-holdings-area',
  simulatorPortfolioStockData: 'simulator-portfolio-stock-data-area',
  leaderboardsPage: 'leaderboards-page-area',
  articlesPage: 'articles-area',
}
